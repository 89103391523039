import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment.development';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any) {
        console.log(error);
        const router = this.injector.get(Router);
        if(!environment.production){
            console.error('Error handled globally:', error.message || error);
        }
       

        /*Handle different error types (optional)
        if (error.status === 404) {
            router.navigate(['/not-found']);
        } else if (error.error instanceof ProgressEvent) {
            Handle network errors
            console.error('Network error:', error);
        } else {
            Generic error handling (fallback)
            router.navigate(['/error'], { queryParams: { error: error.message } });
        }*/
    }
}