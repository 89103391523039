import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  isDropdownOpen = false;
  isNavbarOpen = false;
  toggleDropdown(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleNavbar(){
    this.isNavbarOpen = !this.isNavbarOpen;
  }
}
