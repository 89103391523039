import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    let errorMessage = 'An unexpected error occurred.';
                    switch (error.status) {
                        case 400:
                            if (error.error) {
                                errorMessage = 'Bad request: ' + error.error.message;
                            }
                            break;
                        case 401:
                            errorMessage = 'Unauthorized access.';
                            break;
                        case 404:
                            errorMessage = 'Not found.';
                            break;
                    }
                    // Handle the error here with a user-friendly message based on status code
                    return throwError(() => new Error(errorMessage));
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error('Network error:', error);
                    return throwError(() => new Error('A network error occurred.'));
                }
            }),
            map<HttpEvent<any>, any>(event => {
                if (event instanceof HttpResponse) {
                    // Handle successful responses if needed (optional)
                }
                return event;
            })
        );
    }
}