import { Routes } from '@angular/router';
import { accountGuard } from './features/auth/guards';
import { DashboardPageComponent } from './features/dashboard/ui/pages/dashboard-page/dashboard-page.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: 'dashboard',
        component: DashboardPageComponent
    },
    {
        path: 'ingresar',
        canActivate:[accountGuard],
        loadChildren: () =>
            import('./features/auth/login-routing.module')
                .then(m => m.LoginRoutingModule)
    },
    {
        path: 'registro',
        canActivate:[accountGuard],
        loadChildren: () =>
            import('./features/auth/register-routing.module')
                .then(m => m.RegisterRoutingModule)
    },
    {
        path: 'cuenta',
        loadChildren: () =>
            import('./features/auth/account-routing.module')
                .then(m => m.AccountRoutingModule)
    },
];
